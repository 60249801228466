<script>
import { of, defer, merge } from 'rxjs'
import { share, mapTo } from 'rxjs/operators'
import Types from '@/common/types.js'
import { api } from '@/api'

export default {
  data() {
    return {
      formatter: new Intl.ListFormat('de', {
        style: 'long',
        type: 'conjunction',
      }),
    }
  },
  subscriptions() {
    const record$ = defer(() =>
      api('journey', 'find', {
        url: '/find',
        method: 'POST',
        data: { email: this.$route.params.email },
      }).then(data => {
        let journey = data.journey && Object.freeze(JSON.parse(data.journey))
        journey = journey && {
          ...journey,
          // include wizard editor fields
          ...Object.fromEntries(
            Object.keys(journey['12'] || {}).map(key => [
              `12-${key}`,
              journey['12'][key],
            ])
          ),
        }

        const fieldsOrdered =
          journey == void 0
            ? []
            : Object.keys(journey)
                .filter(key => key !== 'step')
                .sort(
                  (a, b) =>
                    Number(String(a).split('-')[0]) -
                    Number(String(b).split('-')[0])
                )

        const journeyFields = [
          ...new Set(fieldsOrdered.map(e => e.split('-')[0])),
        ].map(group => fieldsOrdered.filter(e => e.split('-')[0] === group))

        return {
          ...data,
          journey,
          journeyFields,
          fieldsFormat: {
            '1': {
              name: 'Welche Designlinie möchtest du für deine neue Küche?',
            },
            '2': {
              name: 'Wo befindest du dich gerade in deinem Kaufprozess?',
            },
            '3': {
              name: 'Warum benötigst du eine neue Küche?',
            },
            '4': {
              name: 'Wie kochst du am liebsten?',
            },
            '5': {
              name:
                'Bitte wähle alle Geräte aus, welche du von kitchX kaufen möchtest.',
            },
            '6': {
              name:
                'Bitte wähle jetzt alle Geräte die du jetzt selber kaufen willst oder bereits vorhanden sind',
            },
            '7': {
              name: 'Welche E-Geräte Marke möchtest du?',
            },
            '8': {
              name: 'Welche Arbeitsplatte gefällt dir?',
            },
            '9': {
              name: 'Wie sollen deine Hängeschränke geplant werden?',
            },
            '10': {
              name: 'Was ist dein Budget für deine Küche?',
            },
            '10-checkbox': {
              name: 'Ich möchte gern in Raten zahlen',
            },
            '11': {
              name:
                'Welche Faktoren sind Dir bei deiner Küche besonders wichtig?',
            },
            '12': {
              name: 'Drawing Tool',
              map: () => '✓',
            },
            '12-cancel': {
              name: 'Drawing canceled',
            },
            '12-comment': {
              name: 'Canceled comment',
            },
            '12-editor': {
              map: editor => {
                return `<a href="${
                  new URL(
                    `${process.env.VUE_APP_EDITOR}?load=${JSON.stringify(
                      editor
                    )}`
                  ).href
                }" target="_blank">Open editor</a>`
              },
            },
            '12-Küchenformen': {
              map: value => {
                const item = Types.Küchenformen.find(
                  item => item.value === value
                )
                return (
                  item &&
                  item.url &&
                  `<img src="${item.url}" width="150" height="150" />`
                )
              },
            },
            // images
            '13': {
              name: 'Rauminformationen hochladen',
              images: true,
            },
            '14': {
              name: 'Wann benötigst du deine Küche?',
            },
            '15': {
              name: 'Benötigst du einen Montageservice?',
            },
            '16': {
              name:
                'Benötigst du einen Demontageservice für eine bestehende Küche?',
            },
            '17': {
              name: 'Wie möchtest du kontaktiert werden?',
            },
            '18': {
              name: 'An wen sollen wir Dein unverbindliches Angebot schicken?',
            },
          },
        }
      })
    ).pipe(share())

    const loadingRecord$ = merge(of(true), record$.pipe(mapTo(false)))

    return {
      record$,
      loadingRecord$,
    }
  },
  methods: {
    getFieldName(name = '') {
      return name.replace(/^[^-]*-/, '')
    },
  },
}
</script>
<template>
  <div class="px-4 px-md-12">
    <div class="mx-auto py-6 pb-10 pt-md-10 container">
      <div class="mb-2 d-flex justify-space-between align-center">
        <p class="mb-0 text-h6 d-flex flex-column d-md-block">
          Küchendesign
        </p>
      </div>

      <v-progress-circular
        v-if="loadingRecord$ === true"
        indeterminate
        color="primary"
        class="mx-auto mt-16 d-block"
      ></v-progress-circular>
      <template v-else>
        <div
          v-for="(group, groupIndex) in record$ && record$.journeyFields"
          :key="groupIndex"
          class="mb-14"
        >
          <div
            v-for="(fieldName, fieldNameIndex) in group"
            :key="fieldName"
            :class="[
              'mb-2 text-h6 font-weight-light',
              { 'ml-1': fieldNameIndex > 0 },
            ]"
          >
            <h5 class="text-h6 font-weight-bold">
              {{
                (record$.fieldsFormat[fieldName] || {}).name ||
                  getFieldName(fieldName)
              }}
            </h5>
            <div
              v-if="(record$.fieldsFormat[fieldName] || {}).images === true"
              style="
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
                grid-gap: 1.5rem;
              "
              class="mt-2"
            >
              <a
                v-for="file in record$.journey[fieldName] || []"
                :key="file.url"
                :href="file.url"
                download=""
                target="_blank"
              >
                <img
                  :src="(file && file.url) || '/statics/placeholder.png'"
                  width="237"
                  height="160"
                  class="d-block rounded"
                  alt="Image preview"
                  style="object-fit: cover;"
                />
              </a>
            </div>
            <div
              v-else-if="(record$.fieldsFormat[fieldName] || {}).map"
              v-html="
                record$.fieldsFormat[fieldName].map(record$.journey[fieldName])
              "
            ></div>
            <template v-else>
              {{
                Array.isArray(record$.journey[fieldName])
                  ? record$.journey[fieldName].length > 0
                    ? formatter.format(record$.journey[fieldName])
                    : '--'
                  : typeof record$.journey[fieldName] === 'boolean'
                  ? record$.journey[fieldName]
                    ? '✓'
                    : '--'
                  : record$.journey[fieldName]
              }}
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
